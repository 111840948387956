import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import HelpIcon from '@mui/icons-material/Help'
import {
  Avatar,
  Box,
  Dialog,
  IconButton,
  OutlinedInput,
  Typography
} from '@mui/material'
import AccountNumberHelp1 from 'assets/images/AccountNumberHelp1.jpg'
import AccountNumberHelp2 from 'assets/images/AccountNumberHelp2.png'
import { CloudPay, ErrorText, MButton } from 'components'
import { MESSAGES } from 'config'
import { FormLabel } from 'containers/Auth/SignIn/component'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { APIs, type IVerifyPayBill } from 'services'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import * as yup from 'yup'

const schema = yup
  .object({
    accountId: yup.string().max(10).required('Account number is required'),
    lastName: yup.string().required('Last name is required')
  })
  .required()

export const Form: React.FC = () => {
  const [account, setAccount] = useState<string>('')
  const [cloudPayVisible, setCloudPayVisible] = useState<boolean>(false)
  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<IVerifyPayBill>({
    mode: 'all',
    defaultValues: {
      accountId: '',
      lastName: ''
    },
    resolver: yupResolver(schema)
  })
  const { enqueueSnackbar } = useSnackbar()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const isMobile = useMobileBreakpoints()

  const onSubmit = async (body: IVerifyPayBill): Promise<void> => {
    try {
      const response = await APIs.verifyPayBill(body)
      if (response.success === true) {
        setAccount(body.accountId)
        setCloudPayVisible(true)
      } else {
        enqueueSnackbar(MESSAGES.VERIFY_PAY_BILL_ERROR, { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar(MESSAGES.VERIFY_PAY_BILL_ERROR, { variant: 'error' })
    }
  }


  const handleCloudPayClose = (): void => {
    setCloudPayVisible(false)
  }

  const handleModalOpen = (): void => {
    setIsModalOpen(!isModalOpen)
  }


  return (
    <>
      <form method="post" onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" flexDirection="column" gap={1} mb={2}>
            <Typography fontSize={16}>Enter your account number and last name/business name to proceed with your payment.</Typography>
          </Box>
          <Controller
            name="accountId"
            control={control}
            render={({ field: { onChange, value, name } }): JSX.Element => (
              <Box>
                <Box display="flex" alignItems="center">
                  <FormLabel>Account Number <IconButton onClick={handleModalOpen} sx={{ padding: 0 }}><HelpIcon color="primary" fontSize="small" /></IconButton></FormLabel>
                </Box>
                <OutlinedInput
                  name={name}
                  placeholder="Enter your account number"
                  onChange={onChange}
                  value={value}
                  type="text"
                  fullWidth
                />
                {(errors.accountId != null) && <ErrorText>{errors.accountId.message}</ErrorText>}
              </Box>
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field: { onChange, value, name } }): JSX.Element => (
              <Box>
                <FormLabel>Last Name / Business Name</FormLabel>
                <OutlinedInput
                  name={name}
                  placeholder="Enter your last name"
                  onChange={onChange}
                  value={value}
                  type="text"
                  fullWidth
                />
                {(errors.lastName != null) && <ErrorText>{errors.lastName.message}</ErrorText>}
              </Box>
            )}
          />

        </Box>
        <Box my={3} display="flex" flexDirection="column" gap={2}>
          <MButton
            variant="contained"
            type="submit"
            size="large"
            rounded="true"
            disabled={!isValid}
            texttransform="none"
            fullWidth
            onSubmit={handleSubmit(onSubmit)}
            sx={{ fontSize: 16 }}
          >
            Pay your Bill
          </MButton>
        </Box>
      </form>
      {cloudPayVisible && (
        <CloudPay account={account} onClose={handleCloudPayClose} />
      )}
      {isModalOpen && <Dialog open={isModalOpen} onClose={handleModalOpen} maxWidth="lg" fullScreen={!!isMobile}>
        <Box m={3} display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleModalOpen} ><CloseIcon color="primary" /></IconButton>
          </Box>
          <Typography fontWeight={500} fontSize={24} textAlign="center">Where can I find my account number?</Typography>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={3}>
            <Box display="flex" flexDirection="column" gap={2} p={2} alignItems="center" justifyContent="center" border='solid 1px #DCE0DF' borderRadius={2} bgcolor='#F5F5F5'>
              <Avatar sx={{ backgroundColor: '#E7E9E9', padding: 1 }}><Typography color="primary" fontSize={18}>1</Typography></Avatar>
              <Typography fontWeight={400} fontSize={16} textAlign="center">Get one of your previous bills</Typography>
              <Box component="img" src={AccountNumberHelp1} width="100%"></Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={2} p={2} alignItems="center" justifyContent="center" border='solid 1px #DCE0DF' borderRadius={2} bgcolor='#F5F5F5'>
              <Avatar sx={{ backgroundColor: '#E7E9E9', padding: 1 }}><Typography color="primary" fontSize={18}>2</Typography></Avatar>
              <Typography fontWeight={400} fontSize={16} textAlign="center">Check the right hand corner, under “Account Summary”</Typography>
              <Box component="img" src={AccountNumberHelp2} width="100%"></Box>
            </Box>
          </Box>
        </Box>
      </Dialog>}

    </>
  )
}
