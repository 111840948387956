import { Box, Link } from '@mui/material'
import type React from 'react'


export const LoginBanner: React.FC = () => {
    return (
        <Box
            sx={{
                bgcolor: '#007395',
                color: 'white',
                py: 3,
                borderRadius: 0,
                textAlign: 'center'
            }}
        >
            <Link sx={{ color: 'white', fontSize: 16, textDecoration: 'underline' }} href="https://www.alexrenew.com/sites/default/files/2024-12/AlexRenew%20FY24%20Annual%20Report.pdf" target='_blank' rel="noopener noreferrer">Click here to view AlexRenew&apos;s FY2024 Annual Report.</Link>
        </Box>

    )
}
