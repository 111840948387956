/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Container, IconButton, Link, Typography, styled } from '@mui/material'
import AlexRenewIcon from 'assets/icons/AlexRenewIcon.svg'
import AlexRenewIconTitle from 'assets/icons/AlexRenewIconTitle.svg'
import WatermarkImg from 'assets/images/watermark.png'
import { MButton, SvgImage, Text } from 'components'
import { LoginBanner } from 'components/ui/Alert/LoginBanner'
import { AUTH_TOPBAR_ITEMS, ROUTES_PATH, SOCIAL_LINKS } from 'config'
import type React from 'react'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IAuth {
  title?: string
  desc?: string
  subTitle?: string
  children: React.ReactNode
  isAuthPage?: boolean
}

export const AuthLayout = ({
  title,
  desc,
  isAuthPage = false,
  children
}: IAuth): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMobileBreakpoints()
  const { width, height } = useWindowSize()
  const authTitle = useMemo(() => {
    const pageData = AUTH_TOPBAR_ITEMS.find(
      (item) => item.href === location.pathname
    )

    return pageData?.title
  }, [location.pathname])

  const handleOpenInNewTab = (url: string): void => {
    window.open(url, '_blank')
  }

  const getGradientStop = (): string => {
    const aspectRatio = width / height

    if (height > 1000 && aspectRatio < 1) {
      return '50vh'
    }

    if (width < 1000 && aspectRatio > 1.7) {
      return '100vh'
    }

    if (aspectRatio < 1.5 || aspectRatio > 2) {
      return '75vh'
    }
    return '50vh'
  }

  const isFullScreen = useMemo(() => {
    if (
      location.pathname === ROUTES_PATH.SERVICES ||
      location.pathname === ROUTES_PATH.SIGNUP_SUCCESS ||
      location.pathname === ROUTES_PATH.FORGOT_PASSWORD
    ) {
      return true
    }

    return false
  }, [location.pathname])

  return (
    <>
      <LoginBanner />
      <Wrapper isFullScreen={isFullScreen} isMobile={isMobile} gradientStop={getGradientStop()}>
        {isAuthPage
          ? (
            <Box display="flex" flexDirection="column" my={5} gap={3} alignItems="center" justifyContent="center">
              <Link href="/" my={3}> <img src={AlexRenewIconTitle} height={75} alt="Logo" /> </Link>
              <Typography textAlign="center" color="#010B0E" fontSize={32}>{title}</Typography>
              <Typography textAlign="center" color="#4d515f" fontSize={14} width={isMobile ? '90%' : '60%'}>{desc}</Typography>
            </Box>
          )
          : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={4}
              px={3}
              sx={{ backgroundColor: isMobile ? '#ffffff' : '#F7F9FB', borderBottom: isMobile ? '1px solid #D7E3E9' : '0px' }}
            >
              <Box display="flex" alignItems="center">
                <Link href="/">
                  {isMobile
                    ? (
                      <img src={AlexRenewIcon} height={25} alt="Logo" />
                    )
                    : (
                      <img src={AlexRenewIconTitle} height={40} alt="Logo" />
                    )}
                </Link>
                {!isMobile && authTitle && (
                  <Text
                    color="#000000"
                    fontSize="32px"
                    fontWeight="600"
                    margin="0 0 0 20px"
                  >
                    {authTitle}
                  </Text>
                )}
              </Box>
              <MButton
                variant="outlined"
                rounded="true"
                texttransform="none"
                onClick={() => {
                  navigate(ROUTES_PATH.SIGNIN)
                }}
              >
                Back to Login
              </MButton>
            </Box>
          )}
        <Container>
          <Content>
            {isMobile && authTitle && (
              <Text
                color="#000000"
                fontSize="24px"
                fontWeight="600"
                padding="20px 0 20px 0"
              >
                {authTitle}
              </Text>
            )}
            <Box width="100%" mb={5}>
              {children}
            </Box>
          </Content>
          <Background src={WatermarkImg} alt="Background" />
          <Footer>
            <Text color="#E3E3E3" fontSize="14px">
              © {new Date().getFullYear()}. All rights reserved.
            </Text>
            <Box display="flex" textAlign="right" gap={1}>
              {SOCIAL_LINKS.map((item) => (
                <IconButton
                  key={item.id}
                  sx={{ p: 0, ml: 2 }}
                  onClick={() => {
                    handleOpenInNewTab(item.link)
                  }}
                >
                  <SvgImage name={item.icon} width={32} height={32} />
                </IconButton>
              ))}
            </Box>
          </Footer>
        </Container>
      </Wrapper>
    </>
  )
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen' && prop !== 'isMobile' && prop !== 'windowSize'
})<{ isFullScreen?: boolean, isMobile?: boolean, gradientStop?: string }>(
  ({ isFullScreen, isMobile, gradientStop }) => ({
    background:
      isFullScreen && !isMobile
        ? 'linear-gradient(to bottom, #F7F9FB 50%, #004976 50%)'
        : `linear-gradient(to bottom, #F7F9FB ${gradientStop}, #004976 50vh)`,
    display: 'flex',
    flexDirection: 'column',
    height: isFullScreen && !isMobile ? '100vh' : '100%',
    position: 'relative'
  })
)

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  position: 'relative',
  zIndex: 1
})

const Footer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  position: 'relative',
  paddingTop: '3vh',
  paddingBottom: '3vh'
})

const Background = styled('img')({
  backgroundRepeat: 'no-repeat',
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '25%',
  zIndex: 0
})
